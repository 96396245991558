

.img-cell-mobile img {
  max-width: calc(100vw - 40px);
}

.img-bg {
  position: relative;
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}

.img-bg-cover {
  position: relative;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}
